.static-page {
  max-width: 90%;
  margin: auto;
  padding: 2rem 0rem 4rem 0rem;
}

.static-page.terms-ar-page,
.static-page.privacy-ar-page,
.static-page.recovery-ar-page,
.static-page.about-ar-page,
.static-page.services-ar-page {
  direction: rtl;
}

.static-page.terms-page span strong span {
  color: red;
}

.static-page.recovery-page p strong {
  color: red;
}

.static-page.about-page p strong {
  color: red;
}

.static-page.services-ar-page p span strong {
  color: red;
}

.static-page.services-en-page p span strong {
  color: red;
}

/* Tamara  */

/* Ar */

.tamara-ar-page {
  direction: rtl;
}

.tamara-page ul.main-ul {
  list-style-type: "-";
}

.tamara-page ul.main-ul li {
  padding: 0rem 0.25rem;
  margin: 0.3rem 0rem;
  font-size: 1.05rem;
}

.tamara-page ul.main-ul table {
  margin: 1rem 0rem;
}

.tamara-page ul.main-ul table td {
  border: 1px solid white;
  border-collapse: collapse;
}

.tamara-page td {
  padding: 0.5rem 0rem;
}

.tamara-page td.middle-td {
  background-color: #f0f0f3;
}

.tamara-page .table-2 {
  margin-bottom: 2rem;
}

.tamara-page .table-2 td {
  padding: 1rem 0rem;
  border: 1px solid transparent !important;
  border-collapse: collapse;
}

.tamara-page .table-2 td.middle-td {
  border: 1px solid white !important;
  border-collapse: collapse;
}

@media (max-width: 768px) {
  .tamara-page table {
    width: 100% !important;
  }
}

/* English */

.tamara-en-page {
  direction: ltr;
}

.tamara-en-page ul.main-ul li {
  padding: 0rem 0.25rem;
  margin: 0.3rem 0rem;
  font-size: 0.95rem;
}
