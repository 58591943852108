.toast-style-main-component-in {
  bottom: 7.5%;
  right: 5%;
  transition: 0.25s all ease-in-out;
}

.toast-style-main-component-out {
  bottom: 7.5%;
  right: -50%;
  transition: 0.25s all ease-in-out;
}
