/* Scroll */

/*  Main Scroll  */

/* body::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* body::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* body::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* body::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* Scroll of Map */

.scrollbar-custom-1 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* .scrollbar-custom-1::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* .scrollbar-custom-1::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* .scrollbar-custom-1::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* .scrollbar-custom-1::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.scrollbar-custom-1 .item {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* Change Color  */

@keyframes colorChangeRed {
  0%,
  100% {
    color: black;
  }
  50% {
    color: red;
  }
}

.change-color-danger {
  animation: colorChangeRed 1.5s infinite; /* Change color every 2 seconds (2s x 2 keyframes) */
}

@keyframes colorChangeGreen {
  0%,
  100% {
    color: black;
  }
  50% {
    color: green;
  }
}

.change-color-green {
  animation: colorChangeGreen 1.5s infinite;
}

/* Highlight Class border */

@keyframes colorChangeGreen {
  0%,
  100% {
    border: 1px solid green;
  }
  50% {
    border: 1px solid rgba(0, 128, 0, 0.3);
  }
}

.addedForHighlight {
  /* border: 2px solid green; */
  animation: colorChangeGreen 1.5s infinite;

  transition: all 2s ease-in-out;
}

@keyframes removeColorChangeGreen {
  0% {
    border: 1px solid green;
  }
  100% {
    border: 1px solid #eeeeee;
  }
}

.removeForHighlight {
  /* border: 2px solid green; */
  animation: removeColorChangeGreen 1.5s;
  animation-iteration-count: 1;
  /* border: 1px solid unset; */
  /* transition: all 1 ease-in-out !important; */
}

/* Bullets */

.cont-of-bulltes .swiper-pagination-bullet-active {
  transform: scale(130%);
}

/* Brands  */

/* .brands-cont .swiper-slide-active {
  transform: scale(2);
} */

.image-of-brand {
  transition: all 0.1s ease-in-out;
  -webkit-filter: grayscale(75%);
  filter: grayscale(75%);
}

.image-of-brand:hover {
  transition: all 0.1s ease-in-out;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transform: scale(1.005);
}

/* Today Offer  */

@media (min-width: 992px) {
  .todays-offer-section .swiper-slide {
    min-height: 390px !important;
  }
  .todays-offer-section .swiper-slide .inner-swiper-div {
    min-height: 380px !important;
  }
}

/* Todays offer item */

.todays-offer-item {
  transition: all 0.2s ease-in-out;
  position: relative;
}

.todays-offer-item:hover {
  transition: all 0.2s ease-in-out;
  box-shadow:
    rgba(60, 64, 67, 0.15) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.05) 0px 1px 3px 1px;
  border: 1px solid #ccc;
  /* transform: translateY(-.5px); */
}

.todays-offer-item .inner-image-zoom {
  position: absolute;
  left: 0%;
  top: -20%;
  min-width: 150px;
  min-height: 150px;
  background-color: #eee;
  border: 2px solid #ccc;
  border-radius: 4px;
  z-index: 100;
}

/* .swiper,
swiper-container {
  overflow: visible !important;
 
} */

.no-back-scroll::-webkit-scrollbar-track {
  background: transparent !important;
}

.no-back-scroll::-webkit-scrollbar {
  width: 6px;
}

/*  Telesales */

.spinner-border-color-telesales {
  /* width: 200px;
  height: 200px;
  border: 5px solid #D9232E; */
  animation: changeBorderColor 2s infinite;
  border-right-color: transparent !important;
}

@keyframes changeBorderColor {
  0% {
    border-color: #d9232e;
  }
  50% {
    border-color: #1c278f;
  }
  100% {
    border-color: #d9232e;
  }
}

/* Loader of Telesales */

.loader-div-telesales {
  /* width: 200px;
  height: 200px;
  border: 5px solid #D9232E; */
  animation: changeColor 2s infinite;
  /* border-right-color: transparent!important; */
}

@keyframes changeColor {
  0% {
    -webkit-filter: grayscale(60%); /* Safari 6.0 - 9.0 */
    filter: grayscale(60%);
  }
  50% {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
  }
  100% {
    -webkit-filter: grayscale(60%); /* Safari 6.0 - 9.0 */
    filter: grayscale(60%);
  }
}

/* Header Border telesales */

.header-user-border-telesales {
  /* width: 200px;
  height: 200px;
  border: 5px solid #D9232E; */
  animation: telesalesBorderColor 1.5s infinite;
  /* border-width: 2px; */
  /* border-right-color: transparent!important; */
}

@keyframes telesalesBorderColor {
  0% {
    border-color: #1c278f;
  }
  50% {
    border-color: #d9232e;
  }
  100% {
    border-color: #1c278f;
  }
}

/* Scrollbar Gifts PDP */

/* .container-of-scroll-gifts-pdp{
  
} */

/* .container-of-scroll-gifts-pdp::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  background-clip: padding-box;
  border: 0.05em solid #eeeeee;
} */

.container-of-scroll-gifts-pdp {
  margin-bottom: 1rem;
}

.container-of-scroll-gifts-pdp::-webkit-scrollbar {
  width: 16px;
  height: 22px;
  background-color: #bbb;
  border: 6px solid white;
  /* background-color: #ddd; */
}

.container-of-scroll-gifts-pdp::-webkit-scrollbar-thumb {
  background-color: #1c278f;
  width: 10px;
  height: 10px;
  background-clip: padding-box;
  border: 5px solid #ffffff;
  border-left: 0;
  border-right: 0;
}

.container-of-scroll-gifts-pdp::-webkit-scrollbar-thumb:hover {
  background-color: #1c288fe3;
}

/* .container-of-scroll-gifts-pdp::-webkit-scrollbar-track {
  background-color: #bbbbbb;
} */

/* Buttons */
.container-of-scroll-gifts-pdp::-webkit-scrollbar-button:single-button {
  background-color: #eee;
  /* display: block; */
  border: 1px solid #aaa;
  height: 25px;
  width: 30px;
  border-radius: 4px;
}

.container-of-scroll-gifts-pdp::-webkit-scrollbar-button:decrement {
  content: "\25B2"; /* Unicode arrow-up character */
  font-size: 12px; /* Adjust the font size */
  color: #fff; /* Change the arrow color */
}

/* 
.container-of-scroll-gifts-pdp::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}

.container-of-scroll-gifts-pdp::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

.container-of-scroll-gifts-pdp::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}

.container-of-scroll-gifts-pdp::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}  */

/* Tamara Modal */

.tamara-modal-cont {
  background-image: url("https://cdn-sandbox.tamara.co/widget-v2/assets/background-gradient.597fbd26.png");
  background-size: cover;
}

.tamara-modal-cont .split-item-component {
  transition: all 0.15s ease-in-out;
}
.tamara-modal-cont .split-item-component:hover {
  transition: all 0.15s ease-in-out;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.tamara-modal-cont .tamara-popup__installment-icon {
  position: relative;
  background-color: #ff9b83;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: 50%;
}

.tamara-modal-cont .tamara-popup__installment-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.tamara-modal-cont .tamara-popup__installment-icon .number-of-installments {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 7px;
  z-index: 10;
}
