@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 auto;
}

img {
  height: 0;
  max-height: 100% !important;
}

/* hide arrow from input[type="number"] */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.disable-scrolling::-webkit-scrollbar {
  display: none;
}

.react-select__control {
  border-color: white !important;
}

/* Navbar BoxShadow */

.floatingNav {
  border-radius: 2px;
  box-shadow: 0px 1px 10px #999;
}

/* Product card hover */

.productCardHover {
  transition: 0.25s all ease-in-out;
  transform: translateY(-0.5px);
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.05) 0px 1px 3px 1px;
}

/* ////////////////////// applePay ////////////////////// */
.applePay {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  /* Use any supported button type. */
  cursor: pointer;
  height: 50px;
  border-radius: 8px;
}

.applePayBlack {
  -apple-pay-button-style: black;
}

.applePayWhite {
  -apple-pay-button-style: white;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.gray-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #94a3b8 #e5e7eb;
}

/* Chrome, Edge, and Safari */
.gray-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.gray-scrollbar::-webkit-scrollbar-track {
  background: #e5e7eb;
}

.gray-scrollbar::-webkit-scrollbar-thumb {
  background-color: #94a3b8;
  border-radius: 19px;
  border: 3px none #ffffff;
}

.gray-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.gray-scrollbar::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.gray-scrollbar::-webkit-scrollbar-thumb {
  background: #94a3b8;
  border: 0px none #ffffff;
  border-radius: 49px;
}

.gray-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #94a3b9;
}

.gray-scrollbar::-webkit-scrollbar-thumb:active {
  background: #a8b8cc;
}

.gray-scrollbar::-webkit-scrollbar-track {
  background: #f5f6f6;
  border: 0px none #ffffff;
  border-radius: 0px;
}

.gray-scrollbar::-webkit-scrollbar-track:hover {
  background: #f5f6f6;
}

.gray-scrollbar::-webkit-scrollbar-track:active {
  background: #f5f6f6;
}

.gray-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

.red-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.red-scrollbar::-webkit-scrollbar-track {
  background: #ef4444;
}

.red-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ef4444;
  border-radius: 19px;
  border: 3px none #ffffff;
}

.red-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.red-scrollbar::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.red-scrollbar::-webkit-scrollbar-thumb {
  background: #ef4444;
  border: 0px none #ffffff;
  border-radius: 49px;
}

.red-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #ef4444;
}

.red-scrollbar::-webkit-scrollbar-thumb:active {
  background: #ef4444;
}

.red-scrollbar::-webkit-scrollbar-track {
  background: #f5f6f6;
  border: 0px none #ffffff;
  border-radius: 0px;
}

.red-scrollbar::-webkit-scrollbar-track:hover {
  background: #f5f6f6;
}

.red-scrollbar::-webkit-scrollbar-track:active {
  background: #f5f6f6;
}

.red-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

/* .SwiperNavBar .swiper-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
} */

/* .nav-slider.swiper-slide {
  width: fit-content !important
} */

.almaneaCMSPage img {
  height: 100%;
}

.PDPFancyBoxContainer .yarl__slide_image {
  width: 1500px !important;
  height: 1500px !important;
}

.ProductCardSwiper .swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
  background-color: #333 !important;
}

.ProductCardSwiper .swiper-pagination-bullet-active {
  background-color: #ef4444 !important;
}

/* New Slider in Home Page  */
/* .offer-top-banner-div {
  position: relative;
}

.offer-top-banner-pre {
  position: absolute;
  top: 0;
  left: 0;
}

.offer-top-banner-pre img {
  width: 110%;
  height: 110%;
  position: relative;
  left: -5%;
  top: -5%;
  border: 0;
  outline: 0;
  object-position: center;
  object-fit: cover;
}

.top-banner .long-video {
  object-fit: cover !important;
}

@media (min-width: 993px) {
  .offer-top-banner-div {
    width: 100%;
    height: auto;
  }

  .offer-top-banner-pre {
    width: 100%;
    height: calc(100vw / 1920 * 668);
  }
}

@media (max-width: 992px) {
  .offer-top-banner-div {
    width: 100%;
    height: auto;
  }

  .offer-top-banner-pre {
    width: 100%;
    height: calc(100vw / 360 * 384);
  }
}

@keyframes topBannerPreOut {
  from {
    transform: scale3d(1, 1, 1);
    transform-origin: left center;
  }

  to {
    transform: scale3d(1, 1, 1);
    display: none;
    transform-origin: left center;
  }
}

@keyframes topBannerPreActive {
  from {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1.1);
    transform-origin: right center;
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transform-origin: right center;
  }
}

@keyframes topBannerTextPreActive {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
    transform-origin: right 70%;
  }

  to {
    opacity: 1;
    transform-origin: right 70%;
  }
}

@keyframes topBannerNextOut {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes topBannerNextActive {
  from {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1.1);
    transform-origin: left center;
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transform-origin: left center;
  }
}

@keyframes topBannerTextNextActive {
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0);
    transform-origin: left 70%;
  }

  to {
    opacity: 1;
    transform-origin: left 70%;
  }
}

@keyframes topBannerFirstPageText {
  from {
    opacity: 0.2;
    transform: translate3d(0, 7px, 0);
  }

  to {
    opacity: 1;
  }
}

@keyframes topBannerFirstPageImage {
  from {
    opacity: 0.2;
    transform: perspective(1000px) translate3d(80px, 0, 110px);
  }

  to {
    opacity: 1;
    transform: perspective(1000px) translate3d(0, 0, 0);
  }
}

.top-banner .offer-link {
  height: 100%;
}

.top-banner .pix-aem-image-center,
.top-banner video {
  width: 110% !important;
  height: 110% !important;
  position: relative;
  left: -5% !important;
  top: -5% !important;
}

.top-banner .swiper-slide.swiper-slide-active img {
  animation-duration: 1.5s;
  animation-name: topBannerFirstPageImage;
  animation-fill-mode: ease-out;
}

.top-banner .swiper-slide.swiper-slide-active .swiper-slide-info {
  animation-duration: 1.2s;
  animation-name: topBannerFirstPageText;
  animation-fill-mode: forwards;
}

.top-banner .swiper-slide.swiper-slide-active.next-banner-active img {
  animation-duration: 1.8s;
  animation-name: topBannerNextActive;
  animation-fill-mode: forwards;
}

.top-banner
  .swiper-slide.swiper-slide-active.next-banner-active
  .swiper-slide-info {
  animation-duration: 1.8s;
  animation-name: topBannerTextNextActive;
  animation-fill-mode: forwards;
}

.top-banner .swiper-slide.swiper-slide-active.pre-banner-active img {
  animation-duration: 1.8s;
  animation-name: topBannerPreActive;
  animation-fill-mode: forwards;
}

.top-banner
  .swiper-slide.swiper-slide-active.pre-banner-active
  .swiper-slide-info {
  animation-duration: 1.8s;
  animation-name: topBannerTextPreActive;
  animation-fill-mode: forwards;
}

.top-banner .swiper-slide-prev img,
.top-banner .swiper-slide-prev video {
  animation-duration: 10s;
  animation-name: topBannerPreOut;
  animation-fill-mode: forwards;
}

.top-banner .swiper-slide-visible img,
.top-banner .swiper-slide-visible video {
  animation-duration: 1s;
  animation-name: topBannerNextOut;
  animation-fill-mode: forwards;
}

@media (min-width: 993px) {
  .top-banner .slide-swiper-shadow[data-v-1218a64b] {
    background-color: #000;
    height: 34.79167vw;
    position: absolute;
    width: 100%;
    z-index: 9;
  }

  .top-banner .swiper-slide[data-v-1218a64b] {
    height: 34.79167vw;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .top-banner .swiper-slide video[data-v-1218a64b] {
    height: 34.79167vw;
    width: 100%;
  }

  .top-banner .swiper-slide .slide-video-show[data-v-1218a64b] {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 9;
  }

  .top-banner .swiper-slide-info[data-v-1218a64b] {
    left: 13.75vw;
    position: absolute;
    top: 6.66667vw;
    width: 25vw;
  }

  .top-banner .swiper-slide-button[data-v-1218a64b] {
    margin-top: 2.5vw;
    max-width: 25vw;
    min-width: 6.25vw;
  }

  .top-banner .swiper-slide-button[data-v-1218a64b] .btn-outer {
    padding: 0 1.25vw;
  }

  .top-banner .swiper-slide-button[data-v-1218a64b] .btn-inner {
    display: inline-block;
    max-width: 22.5vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .top-banner .slide-button-margin[data-v-1218a64b] {
    margin-top: 2.5vw;
  }

  .top-banner .slide-button-nomargin[data-v-1218a64b] {
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .top-banner .slide-swiper-shadow[data-v-1218a64b] {
    background-color: #000;
    height: 106.66667vw;
    position: absolute;
    width: 100%;
    z-index: 9;
  }

  .top-banner .swiper-slide video[data-v-1218a64b],
  .top-banner .swiper-slide[data-v-1218a64b] {
    height: 106.66667vw;
    width: 100%;
  }

  .top-banner .swiper-slide .slide-video-show[data-v-1218a64b] {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 9;
  }

  .top-banner .swiper-slide-info[data-v-1218a64b] {
    left: 6.66667vw;
    position: absolute;
    top: 78.88889vw;
    width: 86.66667vw;
  }
}

@media screen and (min-width: 993px) {
  [dir="rtl"] .swiper-slide-info[data-v-1218a64b] {
    right: 13.75vw;
  }
}

.top-banner .swiper-slide .offer-link {
  display: inline-block;
  height: 100%;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  width: 100%;
}

.top-banner
  .swiper-slide.top-banner-scroll-bottom
  .offer-link[data-v-1218a64b] {
  -webkit-transform: scale3d(0.956, 0.956, 0.956);
  transform: scale3d(0.956, 0.956, 0.956);
} */

/* End New Slider in Home Page  */

.pagebuilder-mobile-hidden {
  display: none;
}

.tooltip-arrow {
  position: absolute;
  top: 100%; /* Position above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #ff0000; /* Match tooltip background color */
  content: '';
}
